/*@maxWidth: 450px;*/
/*@maxWidth: none;*/
@media (orientation: portrait) {
  html {
    overflow-y: hidden;
  }
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /*font-family: 'Roboto', sans-serif;*/
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  /*line-height:30px;*/
  font-weight: 400;
  font-size: 14px;
  /*margin-right:-15px*/
  background: #101010;
}
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
  height: 10px;
}
* {
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none;
  /* Internet explorer depuis la version 10 et Edge */
  user-select: none;
  /* Propriété standard */
}
input,
input:before,
input:after,
textarea,
textarea:before,
textarea:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
::-moz-selection {
  /* Code for Firefox */
  color: initial;
  background-color: transparent !important;
}
/*
::selection {
  //color: initial;
  //background-color: transparent !important;
}
*/
a,
a:visited,
a:hover {
  color: #ababab;
}
h2,
.page h2.ui.header,
.ui.modal > .header:not(.ui) {
  font-size: 0.9rem !important;
  letter-spacing: 0.2rem;
  font-weight: bold;
}
h2 {
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 5px;
}
h3,
.page h3.ui.header {
  font-size: 1rem !important;
  letter-spacing: 0.1rem;
  font-weight: bold;
  text-align: left;
  text-transform: unset;
}
[type='checkbox'] {
  width: 20px;
  height: 20px;
}
.App {
  max-width: 800px;
  max-height: 800px;
  margin: 0 auto;
  background-image: url('background.png');
  background-size: cover;
  background-position: top center;
  text-align: center;
  padding-top: 0px;
  height: 100%;
  /*height: 98vh;*/
  background-image: none;
  background-color: #101010 !important;
}
.App.coach,
.App.admin {
  max-width: none;
}
.App.coach .ui.card.computer-warning,
.App.admin .ui.card.computer-warning {
  display: none;
}
/*
.ui.card.computer-warning {
  position: absolute;
  left: 50%;
  top: 20%;
  width: 150px;
  margin-left: -380px;
  text-align: left;
  padding: 0px;
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0.28571429rem;

  border: 1px solid grey !important;
  .extra {
    padding: 0.3em;
  }
  .content > .description {
    color: #d3d3d3 !important;
  }
  .header {
    color: white !important;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }


  @media screen and (min-width: 780px) {
    width: 200px;
    margin-left: -@maxWidth;
    padding: 2px;
  }
}
*/
.footer {
  color: grey;
}
.footer img {
  height: 25px;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5250ms ease-in;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.ui.text.container {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.card-red {
  background-image: radial-gradient(circle, #fd3d51, #c50518) !important;
}
.card-green {
  background-image: radial-gradient(circle, #8fce4b, #478209);
}
.card-white {
  background-image: linear-gradient(to bottom, #ffffff 0%, #fafafa 100%);
}
.card-dark {
  background-color: rgba(0, 0, 0, 0.6);
}
.color-white {
  color: #ffffff !important;
}
.color-grey {
  color: grey !important;
}
.color-black {
  color: #000000 !important;
}
.color-green {
  color: #089208 !important;
}
.color-red {
  color: #be0a0a !important;
}
.card {
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1) !important;
}
.react-bootstrap-table {
  margin-top: 10px;
}
.react-bootstrap-table .advice.float-right {
  margin-left: 12px;
}
.react-bootstrap-table .table {
  width: 100%;
  table-layout: auto;
  border-spacing: 0;
  border-collapse: collapse;
}
.react-bootstrap-table .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  padding: 8px;
  line-height: 1.42857143;
}
.react-bootstrap-table .table > tbody > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857143;
  white-space: pre-line;
}
.top-5 {
  margin-top: 5px !important;
}
.top-10 {
  margin-top: 10px !important;
}
.top-15 {
  margin-top: 15px !important;
}
.top-20 {
  margin-top: 20px !important;
}
.top-25 {
  margin-top: 25px !important;
}
.top-30 {
  margin-top: 30px !important;
}
.top-40 {
  margin-top: 40px !important;
}
.top-50 {
  margin-top: 50px !important;
}
.top-100 {
  margin-top: 100px !important;
}
.left-10 {
  margin-left: 10px !important;
}
.bottom-0 {
  margin-bottom: 0px !important;
}
.bottom-5 {
  margin-bottom: 5px !important;
}
.bottom-10 {
  margin-bottom: 10px !important;
}
.bottom-15 {
  margin-bottom: 15px !important;
}
.bottom-20 {
  margin-bottom: 20px !important;
}
.bottom-25 {
  margin-bottom: 25px !important;
}
.bottom-30 {
  margin-bottom: 30px !important;
}
.bottom-40 {
  margin-bottom: 40px !important;
}
.bottom-50 {
  margin-bottom: 50px !important;
}
.pad-top-0 {
  padding-top: 0px !important;
}
.pad-top-5 {
  padding-top: 5px !important;
}
.pad-top-10 {
  padding-top: 10px !important;
}
.pad-top-15 {
  padding-top: 15px !important;
}
.pad-top-20 {
  padding-top: 20px !important;
}
.pad-top-25 {
  padding-top: 25px !important;
}
.pad-top-30 {
  padding-top: 30px !important;
}
.pad-bottom-10 {
  padding-bottom: 10px !important;
}
.pad-bottom-20 {
  padding-bottom: 20px !important;
}
.pad-bottom-30 {
  padding-bottom: 30px !important;
}
.pad-bottom-40 {
  padding-bottom: 40px !important;
}
.pad-bottom-5 {
  padding-bottom: 5px !important;
}
.pad-none {
  padding: 0px !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.bold {
  font-weight: 500;
}
.bold.spaced {
  letter-spacing: 0.15rem;
}
i.transparent {
  color: transparent !important;
}
.has_premium .premium-tag,
.has_analysis .analysis-tag {
  display: none;
}
.main-menu.ui.menu {
  max-width: 800px;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-position: top center;
  background-image: url('background.png');
}
.main-menu.ui.menu.bottom {
  background-position: bottom center;
  border-top: 1px solid grey;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 48px;
  /*
		http://stephen.io/mediaqueries/
		iPhone X in portrait & landscape
	*/
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .main-menu.ui.menu.bottom {
    padding-bottom: 25px;
  }
}
.main-menu.ui.menu.bottom .item {
  padding: 5px 0 3px 0;
}
.main-menu.ui.menu .app-logo {
  height: 24px;
}
@media only screen and (max-width: 350px) {
  .main-menu.ui.menu .app-logo {
    /* iPhone 5 only */
    max-width: 240px;
    height: auto;
  }
}
.main-menu.ui.menu .app-logo-menu {
  height: 34px;
  position: relative;
  top: -6px;
  margin: 0 auto;
}
.main-menu.ui.menu .item-container {
  width: 33%;
}
.main-menu.ui.menu .item {
  padding: 5px 0 5px 0;
  text-align: center;
  display: inline-block;
  border-bottom: 1px solid transparent !important;
}
.main-menu.ui.menu .item:before {
  background: transparent !important;
}
.main-menu.ui.menu .item .icon {
  margin: 0;
  color: #b4b4b4;
}
.main-menu.ui.menu .item.active,
.main-menu.ui.menu .item.active:hover {
  background: inherit;
  /* border-bottom: 1px solid red !important;*/
}
.main-menu.ui.menu .item.active .icon,
.main-menu.ui.menu .item.active:hover .icon {
  color: #af0000 !important;
}
.main-menu.ui.menu .item.active.main,
.main-menu.ui.menu .item.active:hover.main {
  /*  border-bottom: 1px solid #fff !important;*/
  padding-bottom: 0px;
}
.main-menu.ui.menu .ui.floating.label {
  top: 0.8em;
  left: 95%;
  font-size: 0.7rem;
}
.ui.avatar.image,
.ui.avatar.image img,
.ui.avatar.image svg,
.ui.avatar.images .image,
.ui.avatar.images img,
.ui.avatar.images svg {
  border-radius: 5px;
}
.app-button {
  color: white !important;
  border: 1px solid #3a3838;
  background-image: linear-gradient(to bottom, #403e3e 0%, #1d1d1d 100%) !important;
}
.app-button.green {
  background-image: linear-gradient(to bottom, #00be00 0%, #006100 100%) !important;
  border: none;
}
.app-button.no-background {
  background-image: none !important;
  color: grey !important;
  border: none;
}
.app-button .icon {
  color: white;
}
.app-toggle {
  padding: 6px 10px;
  display: inline-block;
  border: 1px solid #3a3838;
  border-radius: 8px;
  background-color: #000;
  color: #969696;
}
.app-toggle.small {
  padding: 2px 2px;
}
.app-toggle.active {
  font-weight: bold;
  background-image: linear-gradient(to bottom, #403e3e 0%, #1d1d1d 100%);
  color: white;
}
.app-toggle.left {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.app-toggle.right {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.circular-progress {
  margin: 0 auto;
  width: 140px;
  margin-top: -10px;
}
@media only screen and (max-width: 350px) {
  .circular-progress {
    width: 100px;
  }
}
.ui.card.program-card,
.ui.card.program-card:hover {
  max-height: 200px;
  overflow: hidden;
  border-radius: 0.75rem !important;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.ui.card.program-card > .content > .header:not(.ui),
.card.program-card > .content > .header:not(.ui) {
  font-size: 1.2em;
}
.ui.card > .content.overlay .header,
.ui.card > .content.overlay .meta {
  color: #fff;
}
.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
  text-transform: uppercase;
  font-style: italic;
}
.content.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-top: none;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.content.overlay .label.floating {
  top: 5px;
}
@media screen and (max-width: 320px) {
  .content.overlay {
    padding-top: 5% !important;
  }
  .content.overlay .card.program-card > .content > .header:not(.ui),
  .content.overlay .card.program-card > .content > .header:not(.ui) {
    font-size: 1.08571429em !important;
  }
}
.perfs-container .ui.ribbon.label {
  left: calc(-7rem - 1.3em);
}
.perfs-container .perfs-input-container .ui.ribbon.label {
  left: calc(-5rem - 1.4em);
}
.perfs-container .perfs-input-container .card {
  background: rgba(0, 0, 0, 0.6) !important;
  border: 1px solid grey;
}
.perfs-container .ui.card.perf-display {
  border: 1px solid grey;
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff;
}
.perfs-container .ui.card.perf-display .meta {
  color: #d2d2d2;
}
.perfs-container .ui.card.perf-display .description {
  color: #d2d2d2;
}
.ui.inverted.list .item a:not(.ui):hover {
  color: #ababab !important;
}
.ui.inverted.list .item a:not(.ui) {
  color: #ababab !important;
}
div.main-content {
  height: 100%;
  max-width: 800px;
}
.page {
  color: white;
}
.page .header {
  padding-top: 10px;
  color: white;
  font-weight: 500;
  /* text-transform: uppercase;*/
  text-transform: capitalize;
}
.page .header.no-capitalize {
  text-transform: unset;
}
.page .segment {
  white-space: pre-line;
  /*background: rgba(0, 0, 0, 0.6) !important;*/
  background: transparent !important;
  border-radius: 0.28571429rem;
}
@media only screen and (max-width: 350px) {
  .page .segment {
    padding: 1em 0em;
  }
}
.page .right.floated {
  float: right;
  margin-bottom: 1em;
  margin-right: 0.2em;
  margin-top: 0.2em;
}
.page .left.floated {
  float: left;
  margin-bottom: 1em;
  margin-left: 0.2em;
  margin-top: 0.2em;
}
.page .card-dark {
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0.28571429rem;
  /*max-width: 400px;*/
  margin: 0 auto;
  border: 1px solid grey !important;
}
.page .card-dark .card .header {
  color: white !important;
}
.page .card-dark.transparent {
  background: transparent !important;
}
.page .analysis-container {
  height: 250px;
  height: 35vh;
}
@media only screen and (max-width: 350px) {
  .page .analysis-container {
    height: 25vh;
  }
}
.page .analysis .meta {
  color: grey;
}
.page .analysis .description {
  color: white;
}
.page .ui.selection.dropdown {
  min-width: 9.2em;
}
.page .event .content {
  margin-bottom: 0px !important;
}
.page .event .content .date {
  color: #d4d4d5 !important;
}
.page .event .content .summary {
  color: #fff !important;
}
.page .event .content .extra.text {
  color: #fff !important;
}
.ui.basic.modal {
  background-color: rgba(25, 25, 25, 0.82);
}
.ui.basic.modal.discrete {
  border-bottom: none;
  background-color: #000 !important;
}
.ui.basic.modal > .header {
  border-bottom: 1px solid #2c2c2c;
}
.ui.basic.modal > .header.discrete {
  border-bottom: none;
  background-color: #000 !important;
}
.modal .description,
.ui.card > .content,
.content {
  white-space: pre-line;
}
.ui.fluid.container.pushable {
  margin: 0px 0px 45px 0px !important;
}
.training-log .form textarea,
.training-log .form textarea:focus {
  background-color: rgba(0, 0, 0, 0.32);
  color: rgba(255, 255, 255, 0.43);
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.training-log .card {
  /*	border-top: 1px solid grey;*/
  background: transparent;
  color: #fff;
}
.training-log .card .header,
.training-log .card .content {
  color: #fff !important;
}
.training-log .card .header .description,
.training-log .card .content .description {
  color: #fff !important;
  padding: 0;
}
.sidebar.ui.inverted.icon.menu .item {
  color: grey;
}
.hidden {
  display: none !important;
}
.ui.hidden.divider {
  display: block;
  border-color: transparent !important;
}
.roundToast {
  border-radius: 50px;
  text-align: center;
  min-height: 44px;
  border: 2px solid green;
  opacity: 0.9 !important;
}
.roundToast.Toastify__toast--error {
  border: 2px solid red;
}
.Toastify__toast-container {
  top: 52px !important;
  /*
  .Toastify__toast--error {
    background: #db2828;
  }
  .Toastify__toast--default {
    background: #0f1b29;
    color: #aaa;
    border: 1px solid #aaa;
  }
  .Toastify__progress-bar--default {
    background: #6b6a6a;
  }
  .Toastify__close-button--default {
    color: #fff;
  }
*/
}
.riek {
  border: 1px solid #d3d3d39e;
}
.ui.dark.card {
  background-color: transparent;
}
.ui.dark.card > .content {
  background-color: transparent !important;
}
.ui.dark.card > .content .header {
  color: white;
}
.ui.dark.card > .content .description {
  clear: both;
  color: white;
}
@media only screen and (max-width: 767px) {
  .ui.dark.card.drill-analysis .content .description.left-10 {
    margin-left: 10px !important;
  }
}
.ui.card.transparent {
  background-color: transparent !important;
}
.ui.card.transparent > .content .header {
  color: #ababab;
}
.ui.card.transparent > .content .description {
  clear: both;
  color: #ababab;
}
.ui.card.dark-grey,
.ui.segment.dark-grey {
  /*	background-color: rgba(25, 25, 25, 0.87) !important;*/
  background-color: transparent !important;
}
.ui.card.dark-grey > .content,
.ui.segment.dark-grey > .content {
  /*  background-color: rgba(25, 25, 25, 0.87) !important;*/
  background-color: transparent !important;
}
.ui.card.dark-grey > .content .header,
.ui.segment.dark-grey > .content .header {
  color: #ababab;
}
.ui.card.dark-grey > .content .description,
.ui.segment.dark-grey > .content .description {
  clear: both;
  color: #ababab;
}
.services-card {
  color: #ababab;
}
.services-card .ui.list > .item {
  padding: 1em;
}
.services-card .price {
  margin: 1.6rem;
  font-weight: 600;
  font-size: 2rem;
}
.services-card .price-amount,
.services-card .price-label {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: auto;
}
@media only screen and (max-width: 350px) {
  .services-card .price-amount,
  .services-card .price-label {
    font-size: 1.2rem;
  }
}
.services-card .price-amount.smaller,
.services-card .price-label.smaller {
  font-size: 0.9em;
}
.services-card .price-amount.selected,
.services-card .price-label.selected {
  font-size: 1.7rem;
  color: #fff;
}
@media only screen and (max-width: 350px) {
  .services-card .price-amount.selected,
  .services-card .price-label.selected {
    font-size: 1.3rem;
  }
}
.services-card .price-amount.selected.smaller,
.services-card .price-label.selected.smaller {
  font-size: 1.1rem;
}
.services-card .price-amount {
  font-weight: bold;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
@keyframes waiting {
  from {
    opacity: 0.3;
  }
}
.analysis-waiting {
  animation: waiting 4s infinite alternate;
}
.analysis-ready {
  animation: waiting 1s infinite alternate;
}
.avatar-upload {
  /* margin-top: -50px;*/
}
.avatar-upload .ui.button {
  vertical-align: baseline;
  background: rgba(0, 0, 0, 0.8);
  background: transparent;
  color: #e0e1e2;
}
.avatar-upload .ui.button > .icon {
  margin: 0 !important;
}
.avatar-upload .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.avatar-upload .loader {
  margin-top: 10px;
}
.profilePic-placeholder {
  z-index: 100;
  position: relative;
  min-height: 90px;
}
.profilePic-placeholder .profile-image {
  width: 80px;
  border-radius: 170px;
  /* margin: 20px auto 0px auto;*/
  border: solid 0px #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}
@media (min-height: 620px) {
  .profilePic-placeholder .profile-image {
    width: 120px;
  }
}
div.signup-form,
div.login-form,
div.pending-container {
  height: 100vh;
}
.profile-modal .ui.form .field > .selection.dropdown {
  width: auto;
  border-bottom: 1px solid grey;
  min-height: 0;
  padding: 0.2em 2.1em 0.3em 1em;
  text-align: center !important;
}
.profile-modal .ui.form .field > .selection.dropdown .icon {
  top: 0.4rem;
}
.profile-modal input {
  text-align: center !important;
}
.modal .ui.label.focus img {
  width: 100px !important;
  height: 100px !important;
}
.profile,
.profile-modal,
.login-form,
.signup-form,
.ruffier-form,
.pending-container,
.change-password {
  color: #aaa;
}
.profile a,
.profile-modal a,
.login-form a,
.signup-form a,
.ruffier-form a,
.pending-container a,
.change-password a {
  font-size: 1em;
  color: #aaa;
  /* text-decoration: underline;*/
}
.profile .input i.icon,
.profile-modal .input i.icon,
.login-form .input i.icon,
.signup-form .input i.icon,
.ruffier-form .input i.icon,
.pending-container .input i.icon,
.change-password .input i.icon,
.profile .language-selector i.icon,
.profile-modal .language-selector i.icon,
.login-form .language-selector i.icon,
.signup-form .language-selector i.icon,
.ruffier-form .language-selector i.icon,
.pending-container .language-selector i.icon,
.change-password .language-selector i.icon {
  color: grey;
}
.profile .active i.icon,
.profile-modal .active i.icon,
.login-form .active i.icon,
.signup-form .active i.icon,
.ruffier-form .active i.icon,
.pending-container .active i.icon,
.change-password .active i.icon {
  opacity: 1;
}
.profile .ui.form .field > label,
.profile-modal .ui.form .field > label,
.login-form .ui.form .field > label,
.signup-form .ui.form .field > label,
.ruffier-form .ui.form .field > label,
.pending-container .ui.form .field > label,
.change-password .ui.form .field > label {
  color: grey;
  margin-bottom: 0;
  line-height: 1em;
}
.profile .ui.form input,
.profile-modal .ui.form input,
.login-form .ui.form input,
.signup-form .ui.form input,
.ruffier-form .ui.form input,
.pending-container .ui.form input,
.change-password .ui.form input,
.profile .language-selector,
.profile-modal .language-selector,
.login-form .language-selector,
.signup-form .language-selector,
.ruffier-form .language-selector,
.pending-container .language-selector,
.change-password .language-selector,
.profile .ui.form input:focus,
.profile-modal .ui.form input:focus,
.login-form .ui.form input:focus,
.signup-form .ui.form input:focus,
.ruffier-form .ui.form input:focus,
.pending-container .ui.form input:focus,
.change-password .ui.form input:focus {
  background: transparent;
  color: #fff;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0;
  padding: 0.15em 1em 0.2em;
}
.profile .ui.checkbox label,
.profile-modal .ui.checkbox label,
.login-form .ui.checkbox label,
.signup-form .ui.checkbox label,
.ruffier-form .ui.checkbox label,
.pending-container .ui.checkbox label,
.change-password .ui.checkbox label,
.profile .ui.checkbox label:hover,
.profile-modal .ui.checkbox label:hover,
.login-form .ui.checkbox label:hover,
.signup-form .ui.checkbox label:hover,
.ruffier-form .ui.checkbox label:hover,
.pending-container .ui.checkbox label:hover,
.change-password .ui.checkbox label:hover {
  background: transparent;
  color: #fff;
  border: none;
}
.profile .ui.checkbox,
.profile-modal .ui.checkbox,
.login-form .ui.checkbox,
.signup-form .ui.checkbox,
.ruffier-form .ui.checkbox,
.pending-container .ui.checkbox,
.change-password .ui.checkbox {
  padding: 0.15em 0.5em;
}
.profile .ui.form .field > .selection.dropdown,
.profile-modal .ui.form .field > .selection.dropdown,
.login-form .ui.form .field > .selection.dropdown,
.signup-form .ui.form .field > .selection.dropdown,
.ruffier-form .ui.form .field > .selection.dropdown,
.pending-container .ui.form .field > .selection.dropdown,
.change-password .ui.form .field > .selection.dropdown {
  width: auto;
}
.profile .ui.selection.dropdown,
.profile-modal .ui.selection.dropdown,
.login-form .ui.selection.dropdown,
.signup-form .ui.selection.dropdown,
.ruffier-form .ui.selection.dropdown,
.pending-container .ui.selection.dropdown,
.change-password .ui.selection.dropdown {
  background-color: transparent;
  color: #fff;
}
.profile .ui.selection.visible.dropdown > .text:not(.default),
.profile-modal .ui.selection.visible.dropdown > .text:not(.default),
.login-form .ui.selection.visible.dropdown > .text:not(.default),
.signup-form .ui.selection.visible.dropdown > .text:not(.default),
.ruffier-form .ui.selection.visible.dropdown > .text:not(.default),
.pending-container .ui.selection.visible.dropdown > .text:not(.default),
.change-password .ui.selection.visible.dropdown > .text:not(.default) {
  color: #fff;
}
.profile .ui.selection.active.dropdown:hover,
.profile-modal .ui.selection.active.dropdown:hover,
.login-form .ui.selection.active.dropdown:hover,
.signup-form .ui.selection.active.dropdown:hover,
.ruffier-form .ui.selection.active.dropdown:hover,
.pending-container .ui.selection.active.dropdown:hover,
.change-password .ui.selection.active.dropdown:hover {
  border-color: transparent;
}
.profile .ui.selection.active.dropdown .menu,
.profile-modal .ui.selection.active.dropdown .menu,
.login-form .ui.selection.active.dropdown .menu,
.signup-form .ui.selection.active.dropdown .menu,
.ruffier-form .ui.selection.active.dropdown .menu,
.pending-container .ui.selection.active.dropdown .menu,
.change-password .ui.selection.active.dropdown .menu,
.profile .ui.selection.active.dropdown:hover .menu,
.profile-modal .ui.selection.active.dropdown:hover .menu,
.login-form .ui.selection.active.dropdown:hover .menu,
.signup-form .ui.selection.active.dropdown:hover .menu,
.ruffier-form .ui.selection.active.dropdown:hover .menu,
.pending-container .ui.selection.active.dropdown:hover .menu,
.change-password .ui.selection.active.dropdown:hover .menu {
  border-color: transparent;
  background-color: #343433;
}
.profile .ui.selection.dropdown .menu > .item,
.profile-modal .ui.selection.dropdown .menu > .item,
.login-form .ui.selection.dropdown .menu > .item,
.signup-form .ui.selection.dropdown .menu > .item,
.ruffier-form .ui.selection.dropdown .menu > .item,
.pending-container .ui.selection.dropdown .menu > .item,
.change-password .ui.selection.dropdown .menu > .item {
  background-color: transparent;
  color: #fff;
}
.profile .ui.basic.red.button,
.profile-modal .ui.basic.red.button,
.login-form .ui.basic.red.button,
.signup-form .ui.basic.red.button,
.ruffier-form .ui.basic.red.button,
.pending-container .ui.basic.red.button,
.change-password .ui.basic.red.button {
  color: #fff !important;
}
.profile .app-button,
.profile-modal .app-button,
.login-form .app-button,
.signup-form .app-button,
.ruffier-form .app-button,
.pending-container .app-button,
.change-password .app-button {
  color: white !important;
  background-image: linear-gradient(to bottom, #5f5f5f 0%, #242323 100%) !important;
  border: 1px solid #3a3838;
}
.call-to-buy {
  height: 40px;
  background-image: linear-gradient(to bottom, #ef0202 0%, #720000 100%);
  border-radius: 5px;
  text-align: center;
  /*padding-top: 0.25em;*/
  padding-top: 0.5em;
  color: #fff;
  width: 140px;
  float: right;
  letter-spacing: 0.05em;
}
.call-to-buy.green {
  background-image: linear-gradient(to bottom, #07bc0c 0%, #006903 100%);
}
.call-to-buy.coupon {
  background-image: linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%);
}
.call-to-buy.smaller {
  padding-top: 0.5em;
  height: 30px;
  font-size: 0.9em;
}
.call-to-buy.disabled {
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #9d9d9d 100%);
}
.call-to-buy.disabled .button-text {
  color: #939191;
}
.call-to-buy.fullwidth {
  float: none;
  margin: 0 auto;
  width: 100%;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}
.call-to-buy .button-text {
  text-transform: uppercase;
  color: #fff;
  line-height: 12px;
}
.actions-placeholder .call-to-action {
  width: 416px;
  width: auto;
  margin: 0px auto 20px auto;
  text-align: center;
}
.actions-placeholder .call-to-action.incard {
  width: 100%;
}
.actions-placeholder .call-to-action .action {
  width: 70px;
  height: 70px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  word-spacing: 0.1em;
  display: inline-block;
  position: relative;
  z-index: 100;
  /*        background-color: #FFFFFF;*/
  background-image: linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%);
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 350px) {
  .actions-placeholder .call-to-action .action {
    /* iPhone 5 only */
  }
}
.actions-placeholder .call-to-action .action > i {
  position: absolute;
  top: 20px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 28px;
  /*color: #383838;*/
  color: #ffffff;
  line-height: 20px;
}
.actions-placeholder .call-to-action .action .label > .icon {
  margin: 0;
}
.actions-placeholder .call-to-action .action.highlight {
  width: 290px;
  height: 70px;
  background-image: linear-gradient(to bottom, #ef0202 0%, #720000 100%);
}
.actions-placeholder .call-to-action .action.highlight.premium {
  background-image: linear-gradient(to bottom, #2185d0 0%, #104973 100%);
}
.actions-placeholder .call-to-action .action.highlight.not-orderable {
  opacity: 0.5;
}
.actions-placeholder .call-to-action .action.highlight em {
  color: #f5f5f5;
}
.actions-placeholder .call-to-action .action.highlight.standalone {
  margin-left: 0px;
}
.actions-placeholder .call-to-action .action.highlight.fullwidth {
  width: 100%;
}
.actions-placeholder .call-to-action .action.fullwidth {
  width: 100%;
}
.actions-placeholder .call-to-action .action.long {
  width: 100%;
  max-width: 290px;
  height: 70px;
}
.actions-placeholder .call-to-action .action.large {
  width: 100%;
  max-width: 132px;
  height: 70px;
}
.actions-placeholder .call-to-action .action.large i {
  top: 15px;
}
.actions-placeholder .call-to-action .action.medium {
  width: 100%;
  max-width: 150px;
  height: 70px;
}
.actions-placeholder .call-to-action .action.no-icon {
  height: 50px;
  padding-top: 1em;
  font-size: 1.1rem;
}
.actions-placeholder .call-to-action .action.green {
  background-image: linear-gradient(to bottom, #07bc0c 0%, #006903 100%);
}
.actions-placeholder .call-to-action .action.analysis-ready {
  background-image: linear-gradient(to bottom, #07bc0c 0%, #006903 100%);
}
.actions-placeholder .call-to-action .action.disabled {
  opacity: 0.45;
}
.actions-placeholder .call-to-action em {
  position: absolute;
  bottom: 3px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a8a8a8;
  border-top: solid 1px rgba(0, 0, 0, 0.03);
  line-height: 12px;
}
.actions-placeholder .call-to-action .icongroup .corner.icon {
  top: 10px;
  left: 10px;
  font-size: 0.45em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.Home {
  height: 100%;
}
.Home.custom-buttons {
  padding-top: 0px;
}
@media (min-height: 620px) and (max-height: 720px) {
  .Home.custom-buttons .home-header {
    padding-top: 0px;
  }
}
@media (min-height: 720px) {
  .Home.custom-buttons .home-header {
    padding-top: 0px;
  }
}
.Home .home-header {
  box-shadow: none !important;
}
@media (min-height: 620px) and (max-height: 720px) {
  .Home .home-header {
    padding-top: 20px;
  }
}
@media (min-height: 720px) {
  .Home .home-header {
    padding-top: 40px;
  }
}
.Home .home-header h2 {
  font-size: 21px;
}
.Home .home-header .card {
  box-shadow: none !important;
}
.Home .home-header .description .column {
  margin-left: 0;
  margin-right: 0;
}
.Home .home-header .name-placeholder {
  text-align: right;
  padding-top: 4rem !important ;
  padding-right: 10% !important;
}
.Home .home-header .name-placeholder h2 {
  margin-top: 0px;
}
.Home .home-header .card {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  line-height: normal;
  padding: 0px 0px;
  background: transparent;
}
.Home .home-header .card.inner {
  padding: 2px;
  margin-top: 0;
  margin-bottom: 0;
}
.Home .home-header .card .advice {
  margin: 0 5px;
  text-align: justify;
  text-overflow: ellipsis;
  border-bottom: 1px solid red;
  font-size: 13px;
}
.Home .home-header .card h4 {
  margin: 5px;
}
.Home .home-header .card > .content > .header:not(.ui),
.Home .home-header .card .ui.cards > .card > .content > .header:not(.ui) {
  font-weight: 700;
  font-size: 1.1em;
  margin-top: -0.21425em;
  line-height: 1.18571429em;
}
.Home .home-header .card .meta {
  font-size: 1em;
  color: rgba(255, 255, 255, 0.7);
}
.Home .home-header .card > .content > .meta + .description {
  margin-top: 0.5em;
  white-space: pre-line;
}
.Home .card .meta + .description {
  margin-top: 0.5em;
  white-space: pre-line;
  line-height: normal;
  padding-bottom: 10px;
}
.Home .toggleable {
  min-width: 318px;
  top: 15px;
  padding: 10px;
}
.Home .toggleable .next-class .description {
  text-align: left;
  padding-left: 15px;
  padding-right: 5px;
}
.Home .toggleable .next-class,
.Home .toggleable .card-dark {
  margin-top: 30px;
}
.Home .toggleable .timesheet .header {
  color: #ffffff !important;
}
.Home .call-to-action {
  width: 416px;
  width: auto;
  margin: 0px auto 20px auto;
  text-align: center;
}
.Home .call-to-action.incard {
  width: 100%;
}
.Home .call-to-action .action {
  width: 70px;
  height: 70px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  word-spacing: 0.1em;
  display: inline-block;
  position: relative;
  z-index: 100;
  /*        background-color: #FFFFFF;*/
  background-image: linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%);
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 350px) {
  .Home .call-to-action .action {
    /* iPhone 5 only */
  }
}
.Home .call-to-action .action > i {
  position: absolute;
  top: 20px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 28px;
  /*color: #383838;*/
  color: #ffffff;
  line-height: 20px;
}
.Home .call-to-action .action .label > .icon {
  margin: 0;
}
.Home .call-to-action .action.highlight {
  width: 290px;
  height: 70px;
  background-image: linear-gradient(to bottom, #ef0202 0%, #720000 100%);
}
.Home .call-to-action .action.highlight.premium {
  background-image: linear-gradient(to bottom, #2185d0 0%, #104973 100%);
}
.Home .call-to-action .action.highlight.not-orderable {
  opacity: 0.5;
}
.Home .call-to-action .action.highlight em {
  color: #f5f5f5;
}
.Home .call-to-action .action.highlight.standalone {
  margin-left: 0px;
}
.Home .call-to-action .action.highlight.fullwidth {
  width: 100%;
}
.Home .call-to-action .action.fullwidth {
  width: 100%;
}
.Home .call-to-action .action.long {
  width: 100%;
  max-width: 290px;
  height: 70px;
}
.Home .call-to-action .action.large {
  width: 100%;
  max-width: 132px;
  height: 70px;
}
.Home .call-to-action .action.large i {
  top: 15px;
}
.Home .call-to-action .action.medium {
  width: 100%;
  max-width: 150px;
  height: 70px;
}
.Home .call-to-action .action.no-icon {
  height: 50px;
  padding-top: 1em;
  font-size: 1.1rem;
}
.Home .call-to-action .action.green {
  background-image: linear-gradient(to bottom, #07bc0c 0%, #006903 100%);
}
.Home .call-to-action .action.analysis-ready {
  background-image: linear-gradient(to bottom, #07bc0c 0%, #006903 100%);
}
.Home .call-to-action .action.disabled {
  opacity: 0.45;
}
.Home .call-to-action em {
  position: absolute;
  bottom: 3px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a8a8a8;
  border-top: solid 1px rgba(0, 0, 0, 0.03);
  line-height: 12px;
}
.Home .call-to-action .icongroup .corner.icon {
  top: 10px;
  left: 10px;
  font-size: 0.45em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.Home .right.floated {
  float: right;
  margin-right: 0;
  margin-bottom: 1em;
  margin-right: 0.2em;
  margin-top: 0.2em;
}
.Home .right.floated.no-margin {
  margin: 0;
}
.ui.toggle.checkbox.inverted label:before,
.ui.toggle.checkbox.inverted input:checked ~ .box:before,
.ui.toggle.checkbox.inverted input:checked ~ label:before,
.ui.toggle.checkbox label:hover::before,
.ui.slider.checkbox.inverted label:before,
.ui.slider.checkbox.inverted input:checked ~ .box:before,
.ui.tslideroggle.checkbox.inverted input:checked ~ label:before,
.ui.slider.checkbox label:hover::before {
  background: grey;
}
.ui.slider.checkbox .box:after,
.ui.slider.checkbox label:after {
  background: #cacaca linear-gradient(transparent, rgba(0, 0, 0, 0.05));
}
.ui.slider.checkbox .box:after,
.ui.slider.checkbox label:after {
  background: #cacaca linear-gradient(#4f4f4f 0%, #171717 100%);
}
/*
.ui.toggle.checkbox.inverted input:checked~label:before {
	background-color: #2185d0!important;
}
.ui.toggle.checkbox label:hover::before {
	background-color: red;
	border: none;
}
*/
.stat-card-label {
  text-transform: uppercase;
  color: grey;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 0.3em;
}
.stat-card-icon {
  vertical-align: middle;
}
.stat-card-pose .stat-card-score,
.stat-card-fall .stat-card-score,
.stat-card-pull .stat-card-score {
  font-size: 1.5rem;
}
.stat-card,
.services-card {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.stat-card-progress,
.execution-block-card {
  padding: 5px 5px 5px 10px;
  /*background-color: rgba(29, 29, 29, 0.65);*/
  border-radius: 0.28571429rem;
}
.card.analysis .ui.grid > .row > .column.middle,
.card.analysis .ui.grid > .column.middle {
  text-align: center;
  position: absolute;
  right: 0px;
}
.card.analysis .execution-block {
  text-align: left;
  margin-left: 15px;
  margin: 0 auto;
  white-space: pre-line;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* width: 250px;*/
}
.card.analysis .execution-block .label {
  display: inline-block;
  /* width: 120px;*/
  color: #d7d7d7;
}
.card.analysis .execution-block .data {
  /* width: 70px;*/
  text-align: center;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
}
.card.analysis .execution-block .column.four {
  text-align: center;
}
.card.analysis .execution-block .column.four i.icon {
  font-size: 0.8em;
}
.card.analysis .execution-block.ui.grid .column,
.card.analysis .execution-block.ui.grid > .row {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}
.card.analysis .execution-block .criteria {
  margin-top: 5px;
}
.card.analysis .toggle-higlight {
  font-size: 1.4em;
  color: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.color1,
.color0 {
  color: red;
  display: inline-block;
}
.color2 {
  color: #ffd308;
  display: inline-block;
}
.color3 {
  color: #00ff00;
  display: inline-block;
}
.border-color1,
.border-color0 {
  border: 1px solid red !important;
}
.border-color2 {
  border: 1px solid #ffd308 !important;
}
.border-color3 {
  border: 1px solid #00ff00 !important;
}
.toggle-left-right {
  font-size: 1.1em;
  color: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.execution-block .section,
.modal .section {
  margin-bottom: 1em;
}
.execution-block .section .sectionName,
.modal .section .sectionName,
.execution-block .section .section-name,
.modal .section .section-name,
.execution-block .section .your-execution,
.modal .section .your-execution {
  text-transform: capitalize;
  margin-bottom: 0.3em;
  font-weight: bold;
  font-size: 1.1em;
}
.execution-block .section .intro,
.modal .section .intro {
  margin-bottom: 10px;
  color: #9c9c9c;
}
.execution-block .section .intro::first-line,
.modal .section .intro::first-line {
  font-weight: bold;
}
.page.page-useranalysis .card-dark {
  border-radius: 0.28571429rem;
  /* max-width: 400px;*/
  margin: 0 auto;
  border: none !important;
}
.price-table .choices .column {
  padding: 0.1rem !important;
}
.lesson-card-text {
  background-color: rgba(29, 29, 29, 0.87);
  border-radius: 0.28571429rem;
  padding: 1rem;
  font-size: 1.1rem;
  white-space: pre-line;
}
.metronome {
  font-family: inherit;
}
.metronome.embedded .metronome__bpm,
.metronome.embedded .rc-slider {
  display: none;
}
.metronome .metronome__bpm {
  font-size: 2em;
  color: #888;
  font-weight: 600;
}
.metronome .rc-slider-handle {
  background-color: red;
  border: red;
}
.metronome .rc-slider-handle:active {
  border-color: red;
  box-shadow: 0 0 5px red;
}
.metronome .rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: red;
}
.metronome .slider__button {
  display: none;
}
.metronome-icon {
  animation: metronome 1.5s ease-in-out infinite;
  transform-origin: 50% 100%;
}
@keyframes metronome {
  0%,
  100% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
}
